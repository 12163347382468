import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Icon from '../../Icon';

const photoInfoDistance = '26px';

const FacebookIcon = styled(Icon).attrs({ type: 'FacebookSquare' })`
  color: white;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 8px 16px 8px 16px;
`;

const Photo = styled.div`
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  background: url("${props => props.url}") center center no-repeat;
  background-size: cover;
  border-radius: 100%;
  }
`;

const InfoWrapper = styled.div`
  flex: 1;
  margin-left: ${photoInfoDistance};
  max-width: calc(100% - ${p => p.size}px - ${photoInfoDistance});

  ${Photo} & {
  }
`;

const FacebookProfile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FacebookName = styled.span`
  font-size: ${p => p.size}px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #5d6a74;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 20px);
`;

const FacebookAnchor = styled.a`
  cursor: pointer;
  width: 16px;
  margin-top: 6px;
`;

const DOLRole = styled.span`
  font-size: ${p => p.size}px;
  line-height: 15px;
  letter-spacing: 0.4px;
  color: #787c80;
  font-variant: small-caps;
`;

const StyledFacebookIcon = styled(FacebookIcon)`
  font-size: ${p => p.size}px;
`;

const FacebookUser = ({ photoUrl, fullName, dolRole, profileUrl, sizes, ...props }) => (
  <Wrapper {...props}>
    <Photo url={photoUrl} size={sizes.facebookPhoto} />
    <InfoWrapper>
      <FacebookProfile>
        <FacebookName size={sizes.facebookName}>{fullName}</FacebookName>
        <FacebookAnchor className="facebook-profile" target="_blank" href={profileUrl} rel="noreferrer noopener">
          <StyledFacebookIcon size={sizes.facebookIcon} />
        </FacebookAnchor>
      </FacebookProfile>
      <DOLRole size={sizes.dolRole}>{dolRole}</DOLRole>
    </InfoWrapper>
  </Wrapper>
);

FacebookUser.propTypes = {
  photoUrl: PropTypes.string,
  fullName: PropTypes.string,
  /**
   * Role name in DOL English Center
   * */
  dolRole: PropTypes.string,
  profileUrl: PropTypes.string,
  sizes: PropTypes.shape({
    facebookName: PropTypes.number,
    dolRole: PropTypes.number,
    facebookIcon: PropTypes.number,
    facebookPhoto: PropTypes.number,
  }),
};

FacebookUser.defaultProps = {
  photoUrl: '',
  fullName: '',
  dolRole: 'student',
  profileUrl: '',
  sizes: {
    facebookName: 18,
    dolRole: 13,
    facebookIcon: 16,
    facebookPhoto: 48,
  },
};

export default FacebookUser;
