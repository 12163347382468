import styled from 'styled-components';

export const RedTitle = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.125em;
  text-align: center;
  text-transform: uppercase;
  color: ${p => p.theme.brand};
`;

export const H1 = styled.h1`
  font-size: 3em;
  letter-spacing: 0.4px;
  color: ${p => p.theme.bgHeader};
`;

export const H2 = styled.h2`
  font-size: 2.5em;
  letter-spacing: 0.4px;
  color: ${p => p.theme.bgHeader};
`;

export const H3 = styled.h3`
  font-size: 2em;
  letter-spacing: 0.4px;
  color: ${p => p.theme.bgHeader};
`;

export const H4 = styled.h4`
  font-size: 1.5em;
  letter-spacing: 0.4px;
  color: ${p => p.theme.bgHeader};
`;

export const H5 = styled.h5`
  font-size: 1em;
  letter-spacing: 0.4px;
  color: ${p => p.theme.bgHeader};
`;

export const H6 = styled.h6`
  font-size: 0.8em;
  letter-spacing: 0.4px;
  color: ${p => p.theme.bgHeader};
`;
