import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-responsive-modal';
import { EmbeddedPost, FacebookProvider } from 'react-facebook';
import { Navigation, Pagination } from 'swiper/dist/js/swiper.esm';
import Swiper from 'react-id-swiper/lib/index';
import 'swiper/dist/css/swiper.css';

import facebookReviews from '../../../content/ielts-online-tests/facebook-reviews';
import Icon from '../../Icon';
import FacebookReview from './FacebookReview';

const SwiperWrapper = styled.div`
  margin-bottom: 4em;
  padding: 0 5em;
  position: relative;

  .facebook-reviews-pagination {
    display: none;
  }

  @media screen and (max-width: 850px) {
    padding: 0 3em;

    .facebook-reviews-pagination {
      display: block;
    }
  }

  @media screen and (max-width: 425px) {
    padding: 0 1em;

    .swiper-pagination-bullet {
      width: 5px;
      height: 5px;
    }
  }
`;

const Wrapper = styled.div`
  padding: 2em 0 3em 0;
`;

const ModalHeader = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    padding: 1em;
    align-items: center;
  }
`;

const ReviewCount = styled.span``;

const ModalActions = styled.div`
  display: flex;
  align-items: center;
  width: 3em;
  justify-content: space-between;
`;

const ForwardIcon = styled(Icon).attrs({ type: 'NextArrowIcon' })`
  position: fixed;
  left: calc(50vw + 260px + 24px);
  cursor: pointer;
  top: 50vh;
  z-index: 10001;

  @media screen and (max-width: 850px) {
    display: none;
  }
`;
const BackwardIcon = styled(Icon).attrs({ type: 'PrevArrowIcon' })`
  position: fixed;
  top: 50vh;
  left: calc(50vw - 260px - 80px);
  z-index: 10001;
  cursor: pointer;

  @media screen and (max-width: 850px) {
    display: none;
  }
`;

const ModalForwardIcon = styled(Icon).attrs({ type: 'NextArrowIcon' })`
  position: absolute;
  top: calc(50% - 66px);
  z-index: 1;
  right: 10px;
  cursor: pointer;

  @media screen and (max-width: 850px) {
    display: none;
  }
`;

const ModalBackwardIcon = styled(Icon).attrs({ type: 'PrevArrowIcon' })`
  position: absolute;
  top: calc(50% - 66px);
  z-index: 1;
  left: 10px;
  cursor: pointer;

  @media screen and (max-width: 850px) {
    display: none;
  }
`;

const FacebookContainer = styled.div`
  min-height: 500px;
  max-width: 100vw;
`;
const Title = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 2em;
  text-align: center;
  letter-spacing: 0.4px;
  color: black;
  margin: 3em 0;
`;

const swiperParams = {
  slidesPerView: 'auto',
  spaceBetween: 30,
  freeMode: true,
  modules: [Navigation, Pagination],
  pagination: {
    el: '.swiper-pagination.facebook-reviews-pagination',
    type: 'bullets',
    clickable: true,
  },
};

function FacebookReviews() {
  const [isShowModal, setShowModal] = React.useState(false);
  const [selectedReview, setSelectedReview] = React.useState({});
  const [swiper, setSwiper] = React.useState(null);
  const [isMobile, setIsMobile] = React.useState(false);
  const [viewportWide, setViewPortWide] = useState(null);
  const [modalStyle, setModalStyle] = useState(null);

  useEffect(() => {
    function onResize() {
      setIsMobile(window.innerWidth <= 768);
      setViewPortWide(window.innerWidth - 20 > 350 ? window.innerWidth - 20 : 350);

      if (isMobile) {
        setModalStyle({
          modal: {
            width: '100vw',
            maxWidth: '100vw',
            height: '80vh',
            maxHeight: '80vh',
            padding: '3em 0 0 0',
            marginBottom: 0,
            borerTopLeftRadius: 30,
            borerTopRightRadius: 30,
            overflow: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexFlow: 'column nowrap',
            position: 'relative',
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
          },
          overlay: {
            padding: 0,
          },
        });
      } else {
        setModalStyle({
          modal: {
            width: 550,
            maxWidth: 550,
            minWidth: 320,
            padding: 0,
            borerTopLeftRadius: 30,
            borerTopRightRadius: 30,
            maxHeight: '80vh',
            overflow: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexFlow: 'column nowrap',
            position: 'relative',
          },
          overlay: {
            padding: '2em',
          },
        });
      }
    }

    onResize();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  function onCloseModal() {
    setShowModal(false);
    setSelectedReview({});
  }

  function changeReview(type) {
    const currentIndex = facebookReviews.indexOf(selectedReview);
    const nextReview = facebookReviews[(currentIndex + 1) % facebookReviews.length];
    const prevReview =
      facebookReviews[(currentIndex - 1) % facebookReviews.length] || facebookReviews[facebookReviews.length - 1];

    switch (type) {
      case 'next':
        setSelectedReview(nextReview);
        break;
      case 'prev':
        setSelectedReview(prevReview);
        break;
      default:
        break;
    }
  }

  return (
    <Wrapper>
      <Modal open={isShowModal} onClose={() => onCloseModal()} showCloseIcon={false} styles={modalStyle} center>
        <ModalHeader>
          <ReviewCount>
            {facebookReviews.indexOf(selectedReview) + 1} of {facebookReviews.length} {viewportWide}
          </ReviewCount>
          <ModalActions>
            <Icon type="LeftArrowIcon" onClick={() => changeReview('prev')} />
            <Icon type="RightArrowIcon" onClick={() => changeReview('next')} />
          </ModalActions>
        </ModalHeader>
        <BackwardIcon onClick={() => changeReview('prev')} />
        <ForwardIcon onClick={() => changeReview('next')} />
        <FacebookContainer>
          <FacebookProvider appId="722752771433071" onClose={() => setSelectedReview({})} open>
            <EmbeddedPost href={selectedReview.reviewUrl} />
          </FacebookProvider>
        </FacebookContainer>
      </Modal>
      <Title>What our student say</Title>
      <SwiperWrapper>
        <Swiper {...swiperParams} getSwiper={setSwiper}>
          {facebookReviews.map((facebookReview, index) => (
            <div
              style={{ width: isMobile ? 350 : 410, height: isMobile ? 210 : 270, paddingBottom: 50 }}
              key={`facebook-review-${index.toString()}`}
            >
              <FacebookReview
                isMobile={isMobile}
                {...facebookReview}
                onClick={() => {
                  setShowModal(true);
                  setSelectedReview(facebookReview);
                }}
              />
            </div>
          ))}
        </Swiper>
        <ModalForwardIcon
          onClick={() => {
            if (swiper !== null) swiper.slideNext();
          }}
        />
        <ModalBackwardIcon
          onClick={() => {
            if (swiper !== null) swiper.slidePrev();
          }}
        />
      </SwiperWrapper>
    </Wrapper>
  );
}

export default FacebookReviews;
