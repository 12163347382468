import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import FacebookUser from './FacebookUser';

const Wrapper = styled.article`
  width: 370px;
  height: 210px;
  background-color: white;
  padding: 32px 24px 20px 10px;
  transition: box-shadow 0.3s;
  display: flex;
  flex-flow: column nowrap;
  border: 1px solid rgba(211, 211, 211, 0.2);
  box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.1);

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 324px;
      height: 171px;
      padding: 10px 20px 20px 0;
    `};

  &:hover {
    box-shadow: 10px 10px 25px 0 rgba(0, 0, 0, 0.2);
  }

  .facebook-user {
    padding-right: 0;
  }
`;

const ReviewContent = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-size: 14px;
  margin: 0 15px 0 85px;
  color: #5d6a74;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-height: 1.64; /* fallback */
  max-height: 150px; /* fallback */

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-top: 7px;
      margin-left: 80px;
      -webkit-line-clamp: 3; /* number of lines to show */
      max-height: 100px; /* fallback */
    `};
`;

const FacebookReview = ({ onClick, className, reviewContent, isMobile, ...facebookUser }) => (
  <Wrapper {...{ className, isMobile }} onClick={onClick}>
    <FacebookUser className="facebook-user" {...facebookUser} />
    <ReviewContent className="review-content" isMobile={isMobile}>
      {reviewContent}
    </ReviewContent>
  </Wrapper>
);

FacebookReview.propTypes = {
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  reviewContent: PropTypes.string,
  onClick: PropTypes.func,
};

FacebookReview.defaultProps = {
  isMobile: false,
  className: '',
  reviewContent: '',
  onClick: () => {},
};

export default FacebookReview;
