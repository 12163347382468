import React from 'react';
import styled, { css } from 'styled-components';
import { BigPlayButton, Player } from 'video-react';
import Sticky from 'react-sticky-el';

import * as Typo from '../Typo';
import jsonData from '../../content/ielts-online-tests/special-things';
import Icon from '../Icon';

import aiRecommendations from '../../videos/ielts-online-tests/ai-recommendations.mp4';
import explanations from '../../videos/ielts-online-tests/explanations.mp4';
import realifeInterface from '../../videos/ielts-online-tests/real-life-interface.mp4';
import recentActualTests from '../../videos/ielts-online-tests/recent-actual-tests.mp4';
import tipsAndTechniques from '../../videos/ielts-online-tests/tips-and-techniques.mp4';

const mapper = {
  'ai-recommendations.mp4': aiRecommendations,
  'explanations.mp4': explanations,
  'real-life-interface.mp4': realifeInterface,
  'recent-actual-tests.mp4': recentActualTests,
  'tips-and-techniques.mp4': tipsAndTechniques,
};

const Wrapper = styled.div`
  padding: 3em 0;
  font-size: 1rem;

  @media screen and (max-width: 425px) {
    font-size: 0.8rem;
  }
`;

const Title = styled(Typo.H3)`
  text-align: center;
  margin-bottom: 2em;
  
  @media screen and (max-width: 425px) {
    font-size: 1.85em;
    padding: 0 1em;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-evenly;
  position: relative;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    flex-flow: column nowrap;
  }
`;

const SpecialTitle = styled(Typo.H4)`
  font-size: 1.725em;
  color: black;
  transition: color 0.3s;
  font-weight: 300;
`;

const SpecialDescription = styled(Typo.H5)`
  font-size: 1.125em;
  font-weight: 300;
  color: #434343;
`;

const SpecialThing = styled.div`
  padding-left: 1.5em;
  border-left: 0.3em solid transparent;
  transition: border-left-color 0.3s;

  ${p =>
    p.active &&
    css`
      border-left-color: ${p.theme.brand};

      ${SpecialTitle} {
        color: ${p.theme.brand};
      }
    `}

  &:not(:last-child) {
    margin-bottom: 5em;
  }
`;

const SpecialThingsContainer = styled.div`
  width: 40%;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const VideoContainer = styled.div`
  display: none;
  width: 50%;
  top: 0;
  transition: margin-top 0.2s;

  @media screen and (max-width: 768px) {
    display: block;
    width: 100%;
    padding: 0 1em;
    min-height: 230px;
  }
`;

const VideoContainerWithSticky = styled(VideoContainer)`
  display: block;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const SpecialThingsMobileContainer = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    margin-top: 2em;
  }
`;
const SpecialTitleMobile = styled(Typo.H5)`
  color: black;
  margin: 0;
`;
const SpecialThingHeader = styled.div`
  padding: 1em 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  z-index: 1;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);

  ${p =>
    p.active &&
    css`
      background-color: ${p.theme.brand};

      ${SpecialTitleMobile} {
        color: white;
      }
    `}
`;

const SpecialThingMobile = styled.div``;
const SpecialDescriptionMobile = styled.p`
  margin: 0;
  font-size: 1em;
  color: #7f7f7f;
`;

const SpecialThingBody = styled.div`
  transition: height 0.3s linear 0.3s;
  overflow: hidden;
  padding: 0;
  height: 0;

  ${p =>
    p.active &&
    css`
      height: auto;
      padding: 1em 2em;
    `}
`;

const Minus = styled(Icon).attrs({ type: 'Minus' })`
  font-size: 0.8em;
  color: ${p => (p.active ? 'white' : p.theme.brand)};
`;

const Plus = styled(Icon).attrs({ type: 'Plus' })`
  font-size: 0.8em;
  color: ${p => (p.active ? 'white' : p.theme.brand)};
`;

function SpecialThings() {
  const [activeSpecialThing, setActiveThing] = React.useState(jsonData.things[0]);
  return (
    <Wrapper>
      <Title>{jsonData.title}</Title>
      <Content className="special-things-content">
        <VideoContainerWithSticky>
          <Sticky boundaryElement=".special-things-content" stickyStyle={{ marginTop: 75 }} hideOnBoundaryHit>
            <Player src={mapper[activeSpecialThing.video]} autoPlay loop>
              <BigPlayButton position="center" />
            </Player>
          </Sticky>
        </VideoContainerWithSticky>

        <VideoContainer>
          <Player src={mapper[activeSpecialThing.video]} autoPlay loop>
            <BigPlayButton position="center" />
          </Player>
        </VideoContainer>

        <SpecialThingsContainer>
          {jsonData.things
            .sort((order1, order2) => order1.order - order2.order)
            .map(thing => (
              <SpecialThing
                active={activeSpecialThing === thing}
                key={thing.id}
                onMouseOver={() => setActiveThing(thing)}
                onFocus={() => setActiveThing(thing)}
              >
                <SpecialTitle>{thing.title}</SpecialTitle>
                <SpecialDescription>{thing.description}</SpecialDescription>
              </SpecialThing>
            ))}
        </SpecialThingsContainer>
        <SpecialThingsMobileContainer>
          {jsonData.things
            .sort((order1, order2) => order1.order - order2.order)
            .map(thing => (
              <SpecialThingMobile
                key={thing.id}
                onMouseOver={() => setActiveThing(thing)}
                onFocus={() => setActiveThing(thing)}
              >
                <SpecialThingHeader active={activeSpecialThing === thing}>
                  <SpecialTitleMobile>{thing.title}</SpecialTitleMobile>
                  {activeSpecialThing === thing ? (
                    <Minus active={activeSpecialThing === thing} />
                  ) : (
                    <Plus active={activeSpecialThing === thing} />
                  )}
                </SpecialThingHeader>
                <SpecialThingBody active={activeSpecialThing === thing}>
                  <SpecialDescriptionMobile>{thing.description}</SpecialDescriptionMobile>
                </SpecialThingBody>
              </SpecialThingMobile>
            ))}
        </SpecialThingsMobileContainer>
      </Content>
    </Wrapper>
  );
}

export default SpecialThings;
