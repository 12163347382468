import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import * as Typo from '../Typo';
import hero from '../../content/ielts-online-tests/hero';
import Button from '../Button';
import BottomLeftBgi from '../../images/ielts-online-tests/bottom-left-bgi.png';
import TopRightBgi from '../../images/ielts-online-tests/top-right-bgi.png';

const Login = styled(Button)`
  text-transform: capitalize;
  font-family: Montserrat, sans-serif;
  border-radius: 6px;
  font-weight: 300;
  font-size: 1.325em;
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-height: 900px;
  background: url(${BottomLeftBgi}) bottom left no-repeat, url(${TopRightBgi}) top right no-repeat;
  font-size: 1rem;

  @media screen and (max-width: 425px) {
    font-size: 0.8rem;
    background-size: 10em, 10em;
  }

  a {
    text-decoration: none;
  }
`;

const Title = styled(Typo.H1)`
  text-align: center;
  margin: 1em 0 1.5em 0;
  max-width: 20em;

  @media screen and (max-width: 425px) {
    font-size: 1.85em;
    padding: 0 0.5em;
  }
`;

function Hero() {
  return (
    <Wrapper>
      <Typo.RedTitle>{hero.title}</Typo.RedTitle>
      <Title>{hero.description}</Title>
      <Link to={hero.button.href}>
        <Login>{hero.button.text}</Login>
      </Link>
    </Wrapper>
  );
}

export default Hero;
