import React from 'react';

import * as OnlineTests from '../components/IELTSOnlineTests';
import SEO from '../components/SEO';
import Container from '../components/Container';
import { MainLayout } from '../components/Layout';

function IELTSOnlineTests() {
  return (
    <MainLayout>
      <SEO title="Home" keywords={[`dolonline`, `dolenglish`, `ielts`]} />
      <Container bgc="#f9f9f9">
        <OnlineTests.Hero />
      </Container>
      <Container>
        <OnlineTests.SpecialThings />
      </Container>
      <Container bgc="#f9f9f9">
        <OnlineTests.FacebookReviews />
      </Container>
      <Container>
        <OnlineTests.LoginCTA />
      </Container>
    </MainLayout>
  );
}

export default IELTSOnlineTests;
